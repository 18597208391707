import { showMessage } from '../../common/messages/framework/store/message-actions';
import { POST_PIN } from '../components/messages/message-types';
import { getPost } from '../selectors/post-selectors';

export const PIN_POST_REQUEST = 'post/PIN_REQUEST';
export const PIN_POST_SUCCESS = 'post/PIN_SUCCESS';
export const PIN_POST_FAILURE = 'post/PIN_FAILURE';

export function pinPost(id, expiresInMinutes) {
  return (dispatch, getState, { request }) => {
    const state = getState();
    dispatch({
      type: PIN_POST_REQUEST,
    });

    const hasBody = expiresInMinutes !== undefined;
    const body = hasBody
      ? {
          expiresInMinutes,
        }
      : {};

    const promise = hasBody
      ? request.put(`/platformized/v1/posts/${id}/pin`, body)
      : request.post(`/posts/${id}/pin`);

    return promise
      .then((post) => {
        post = post?._id ? post : { ...getPost(state, id), isPinned: true };
        dispatch(showMessage(POST_PIN));
        return dispatch({
          type: PIN_POST_SUCCESS,
          payload: post,
        });
      })
      .catch(() =>
        dispatch({
          type: PIN_POST_FAILURE,
        }),
      )
      .then(() => promise);
  };
}
