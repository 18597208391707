import { Ordering } from '@wix/comments-ooi-client/controller';

export const getCommentsPaginationConfig = (commentLimit: number) => {
  return {
    replyShowMoreLimit: 2,
    ordering: Ordering.LATEST_FIRST,
    initialPage: {
      offset: 0,
      commentLimit,
      replyLimit: 0,
    },
    pagination: {
      commentLimit: 3,
      replyLimit: 3,
    },
    maxPagesBeforeDrop: {
      commentPages: 2,
      replyPages: 4,
    },
  };
};
